export default {
  mesh: 'Mesh',
  notifications: 'Notifications',
  'help-center': "Centre d'aide",
  header: {
    myCollabs: 'Mes collabs',
    summary: 'Récap',
    activityManagement: "Gestion d'activité"
  },
  nomenclature: {
    allStore: 'Tout le magasin',
    levels: {
      allUniverses: 'Tous les univers',
      univers: 'Univers',
      department: 'Rayon Inter. | Rayon Inter. | Rayons Inter.',
      market: 'Marché | Marché | Marchés',
      category: 'Catégorie | Catégorie | Catégories',
      family: 'Famille | Famille | Familles'
    }
  },
  collaborators: {
    addCollaborator: 'Ajouter un collaborateur',
    isExisting: 'Tu as déjà ajouté ce collaborateur',
    someCollaborators: 'Plusieurs collabs',
    assignCollaborator: 'Assigné un collab'
  },
  responsability: {
    univers: ctx => `J'ai${ctx.named('partial') ? ', en partie,' : ''} la reponsabilité de cet univers`,
    department: ctx => `J'ai${ctx.named('partial') ? ', en partie,' : ''} la reponsabilité de ce rayon inter.`,
    market: ctx => `J'ai${ctx.named('partial') ? ', en partie,' : ''} la reponsabilité de ce marché`,
    category: ctx => `J'ai${ctx.named('partial') ? ', en partie,' : ''} la reponsabilité de cette catégorie`,
    family: ctx => `J'ai${ctx.named('partial') ? ', en partie,' : ''} la reponsabilité de cette famille`
  },
  activities: {
    SOME: 'Plusieurs activités',
    ALL: 'Toute la gérance',
    outofstock: 'Ruptures',
    pilot: 'Pilotage',
    stick: 'Pingouin Sticks',
    command: 'Prépa cmd'
  },
  areas: {
    storeAreas: 'Les zones de mon magasin',
    addArea: 'Ajouter une zone',
    addGroup: 'Ajouter un groupe',
    activateEditionMode: 'Activer le mode édition',
    desactivateEditionMode: 'Désactiver le mode édition',
    noAreas: "Il n'y a pas encore de zones créées sur ce magasin. Merci de voir avec le RPPMS pour les créer",
    confirmAreaDelete: ctx => `La zone ${ctx.named('label')} n'est pas vide. Es tu sûr de vouloir la supprimer ?`,
    confirmGroupDelete: ctx => `Le group ${ctx.named('label')} n'est pas vide. Es tu sûr de vouloir le supprimer ?`,
    affectCollabs: 'Affecter des groupes à mes collabs',
    dragGroupsHere: 'Glisse des groupes ici',
    addCollabs: 'Ajoute tes collaborateurs sur la page',
    deletedGroup: 'Groupe supprimé',
    groupsWithoutCollabs: 'Aucun groupe non couvert. | {count} groupe non couvert | {count} groupes non couverts',
    noCollabs: 'Pas de collab affecté',
    collabsAffectedOn: 'Collaborateurs affectés sur',
    'no-group-selected-1': "Sélectionne un groupe",
    'no-group-selected-2': "pour voir les collabs assignés !",
    collabsManagement: {
      title: 'Gérer les collabs',
      siteInfo: 'Tous les collabs du magasin - ',
      collabsCount: 'Aucun collaborateur renseigné. | {count} collaborateur renseigné | {count} collaborateurs renseignés',
      searchCollabs: {
        searchPlaceholder: 'Chercher un collaborateur'
      },
      add: 'Ajouter',
      added: 'Ajouté',
      foreign: 'Collaborateur d\'un autre site'
    }
  },
  print: 'Imprimer',
  saved: 'Sauvegardé',
  loginTo: 'Se connecter à '
}
